<template>
  <div class="wrap">
    <div v-if="!isFullScreen" class="header no-print">
      <div class="header-left" @click="collapseMenu">
        <i v-if="!isCollapse" style="cursor: pointer" class="el-icon-s-fold"></i>
        <i v-if="isCollapse" style="cursor: pointer" class="el-icon-s-unfold"></i>
      </div>
      <div class="tenant-name">
        {{ tenantName }}
      </div>
      <div class="top-router">
        <el-tabs style="width: 100%" v-model="$store.state.permission.activeTopRouter" @tab-click="handleClick">
          <el-tab-pane
              v-for="(item,index) in $store.state.permission.topRouterList"
              :key="index"
              :label="item.name"
              :name="item.name"
          >
            <div slot="label" class="tabLabel">
              {{item.name}}
              <div class="tabLine" v-if="$store.state.permission.activeTopRouter == item.name"></div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="header-right">
        <global-organization v-show="globalOrg"/>
        <span class="item" @click="handleContactUs">联系我们</span>
        <to-do-list v-if="!userUnAuth" ref="child"></to-do-list>
        <div class="usr_info">
          <el-dropdown @command="handleActions">
              <span class="el-dropdown-link">
                {{ nickName }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item command="PersonalCenter">个人中心</el-dropdown-item> -->
              <el-dropdown-item command="SignOff">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div style="display: flex;width: 100%;height: calc(100% - 40px)">
      <div v-if="!isFullScreen" class="menu no-print" :class="menuToggle">
        <!--        <menu-header ref="menuHeader" :is-collapse="isCollapse"></menu-header>-->
        <div class="menu-content">
          <nav-menu :is-collapse="isCollapse" ref="navMenu"></nav-menu>
          <div v-if="userUnAuth" @click="handleUnAuthTip" class="un-auth-mask"
               :style="isCollapse ? 'width: 64px' : 'width:200px'"></div>
        </div>
        <!--        <div class="menu-footer" v-if="!isCollapse">-->
        <!--          <div class="version">version: {{ version }}</div>-->
        <!--          <img src="@/assets/logo/left-menu.png" alt="menuFoot"/>-->
        <!--        </div>-->
      </div>
      <div class="main">
        <!-- <div v-if="!isFullScreen" class="no-print">
          <bread-crumb></bread-crumb>
        </div> -->
        <!--        :closable="tabsList.length > 1"-->
        <!-- tag盒子 -->
        <div v-if="contextmenuFlag" class="right-click-menu"
             :style="{left:contentmenuX+'px',top:contentmenuY+'px'}">
          <div class="item" @click="closeOthersTags">关闭其他
          </div>
          <div class="item" @click="closeAllTags">关闭所有
          </div>
        </div>
        <el-tabs v-if="tabsList.length" v-model="curTab" type="border-card"
                 @contextmenu.native="handleContextmenu"
                 @tab-click="tabClick" @tab-remove="tabRemove" style="height: 38px; margin-bottom: 20px">
          <el-tab-pane v-for="item in tabsList" :key="item.path" :label="item.name"
                       :closable="item.path!=='/open/billing-statistics'"
                       :name="JSON.stringify({ path: item.path, query: item.query })"></el-tab-pane>
        </el-tabs>
        <div class="content">
          <router-view></router-view>
        </div>
        <!--        <div class="record" v-html="copyRight"></div>-->
      </div>
    </div>
    <el-dialog append-to-body class="dialog" title="联系我们" :visible.sync="contactUsOpen" width="500px">
      <div v-loading="contactUsLoading" style="text-align: initial; padding: 20px 50px">
        <div v-for="(item, idx) in contactPhones" :key="idx">
          <img src="@/assets/icon/service.png"/>
          <span>联系电话-{{ idx + 1 }}：{{ item }}</span>
        </div>
      </div>
      <img class="backImg" src="@/assets/icon/contact.png"/>
    </el-dialog>
    <el-dialog append-to-body class="dialog" :title="title" :visible.sync="shopService" width="428px">
      <div>
        <span>请联系我公司商务人员，赵经理</span>
      </div>
      <div>
        <img src="@/assets/icon/telephone.png"/>
        <span>18511536389</span>
      </div>
      <img class="backImg" src="@/assets/icon/contact.png"/>
    </el-dialog>
    <login-expired></login-expired>
    <el-dialog append-to-body class="dialog" :title="unAuthTitle" :visible.sync="unAuthVisible" :center="true"
               :close-on-click-modal="false" width="60%">
      <menu-explain style="padding: 0px 30px 20px 30px" :content="auAuthDescription"></menu-explain>
    </el-dialog>
  </div>
</template>
<script>
import LoginExpired from '@/components/login/Expired';
import MenuExplain from '@/views/global/MenuExplain.vue';
import NavMenu from '@/components/layout/NavMenu';
import BreadCrumb from '@/components/layout/BreadCrumb';
import ToDoList from '@/components/layout/ToDoList';
import MenuHeader from '@/entry/pdt/component/MenuHeader';
import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import GlobalOrganization from '@/components/widgets/GlobalOrganization';
import {ReqSignOff} from '@/service/login';
import {getTntConfig} from '@/service/system/config';
import {copyRightMixin, topTabsMixin} from '@/util/mixins';
import store from "@/store";
import permission from "@/store/modules/permission";

const packInfo = require('../../../package.json');
export default {
  name: 'Layout',
  mixins: [copyRightMixin, topTabsMixin],
  components: {
    NavMenu,
    BreadCrumb,
    ToDoList,
    LoginExpired,
    MenuExplain,
    MenuHeader,
    OrganizationSelect,
    GlobalOrganization
  },
  data() {
    return {
      contentmenuX: "",
      contentmenuY: "",
      contextmenuFlag: false,
      centerColData: '',
      colData: '',
      version: packInfo.version,
      hotelColData: '',
      merchandiseInfoData: '',
      customerInfoData: '',
      recognizeFailureLineSet: '',
      originalOrderLineSet: '',
      middleKindLineSet: '',
      otherInvoiceLineSet: '',
      setOrgId: '',
      isFullScreen: false,
      disabled: false,
      title: '',
      hadLogin: true,
      contactUsOpen: false, //联系我们
      contactPhones: ['', ''],
      contactUsLoading: false,
      shopService: false, //购买服务
      isCollapse: false, //导航栏缩小
      nickName: localStorage.getItem('nickName'),
      tenantName:localStorage.getItem('nameSetting'),
      unAuthTitle: '票单通功能介绍',
      unAuthVisible: false,
      auAuthDescription: ''
    };
  },
  created() {
    if (document.body.clientWidth < 1400) {
      this.isCollapse = true;
      // this.disabled = true;
    }
    // console.log("tenantName",tenantName)
  },
  computed: {
    menuToggle() {
      return this.isCollapse ? 'menu_64' : 'menu_auto';
    },
    userUnAuth() {
      return !this.$store.state.user.currUser.enterprise;
    },
    globalOrg() {
      return this.$store.state.sys.show;
    }
  },
  mounted() {
    window.onresize = () => {
      if (document.body.clientWidth < 1400) {
        this.isCollapse = true;
        // this.disabled = true;
      } else {
        this.isCollapse = false;
        this.disabled = false;
      }
    };
  },
  methods: {
    handleContextmenu(event) {
      let target = event.target;
      // 解决 https://github.com/d2-projects/d2-admin/issues/54
      let flag = false;
      if (target.className.indexOf("el-tabs__item") > -1) flag = true;
      else if (target.parentNode.className.indexOf("el-tabs__item") > -1) {
        target = target.parentNode;
        flag = true;
      }
      if (flag) {
        event.preventDefault();
        event.stopPropagation();
        this.contentmenuX = event.clientX;
        this.contentmenuY = event.clientY;
        this.contextmenuFlag = true;
        window.addEventListener('click', this.watchContextmenu, {once: true});
      }
    },
    watchContextmenu() {
      window.removeEventListener('click', this.watchContextmenu);
      this.contextmenuFlag = false;
    },
    // 关闭其他
    closeOthersTags() {
      this.tabCloseOther()
      this.contextmenuFlag = false;
    },
    // 关闭所有
    closeAllTags() {
      this.tabCloseAll()
      this.contextmenuFlag = false;
    },
    // 顶部主路由点击
    handleClick(tab) {
      let f = this.$store.state.permission.topRouterList.find(item => {
        return item.name === tab.name
      })
      if (f && this.hasMenus(f)) {
        this.$store.commit('permission/SET_LEFT_ROUTES', JSON.parse(JSON.stringify(f.children)))
        this.$forceUpdate()
      } else {
        this.$router.push(f.path)
      }
    },
    hasMenus: (menu) => {
      return (
          menu.meta &&
          menu.meta.menu &&
          menu.children &&
          menu.children.some((i) => i.meta && i.meta.menu)
      );
    },
    handleUnAuthTip() {
      this.unAuthVisible = true;
    },
    handleActions(command) {
      switch (command) {
        case 'SignOff':
          this.doSignOff();
          break;
        case 'PersonalCenter':
          this.personalCenter();
          break;
      }
    },
    handleDeviceState() {
      this.$router.push('/system/equipment');
    },
    handleContactUs() {
      this.contactUsOpen = true;
      this.contactUsLoading = true;
      getTntConfig('BASE_SYS_CONTACT_PHONE').then((res) => {
        this.contactUsLoading = false;
        if (res.success && res.data) {
          this.contactPhones = res.data.split(/;|；/);
        }
      });
    },
    async doSignOff() {
      await ReqSignOff();
      // this.$refs.menuHeader.handleTitle('登录页面', 'favicon.ico');
      this.colData = JSON.parse(localStorage.getItem('lineSet'));
      this.centerColData = JSON.parse(localStorage.getItem('centerLineSet'));
      this.hotelColData = JSON.parse(localStorage.getItem('hotelLineSet'));
      this.merchandiseInfoData = JSON.parse(localStorage.getItem('merchandiseInfo'));
      this.customerInfoData = JSON.parse(localStorage.getItem('customerInfo'));
      this.recognizeFailureLineSet = JSON.parse(localStorage.getItem('recognizeFailureLineSet'));
      this.originalOrderLineSet = JSON.parse(localStorage.getItem('originalOrderLineSet'));
      this.middleKindLineSet = JSON.parse(localStorage.getItem('middleKindLineSet'));
      this.emailInfoData = JSON.parse(localStorage.getItem('emailInfo'));
      this.eventInfoData = JSON.parse(localStorage.getItem('eventInfo'));
      this.otherInvoiceLineSet = JSON.parse(localStorage.getItem('otherInvoiceLineSet'));
      this.setOrgId = JSON.parse(localStorage.getItem('setOrgId'));
      const activeName = localStorage.getItem('activeName');
      localStorage.clear();
      localStorage.setItem('lineSet', JSON.stringify(this.colData));
      localStorage.setItem('centerLineSet', JSON.stringify(this.centerColData));
      localStorage.setItem('hotelLineSet', JSON.stringify(this.hotelColData));
      localStorage.setItem('merchandiseInfo', JSON.stringify(this.merchandiseInfoData));
      localStorage.setItem('customerInfo', JSON.stringify(this.customerInfoData));
      localStorage.setItem('recognizeFailureLineSet', JSON.stringify(this.recognizeFailureLineSet));
      localStorage.setItem('originalOrderLineSet', JSON.stringify(this.originalOrderLineSet));
      localStorage.setItem('middleKindLineSet', JSON.stringify(this.middleKindLineSet));
      localStorage.setItem('otherInvoiceLineSet', JSON.stringify(this.otherInvoiceLineSet));
      localStorage.setItem('emailInfo', JSON.stringify(this.emailInfoData));
      localStorage.setItem('eventInfo', JSON.stringify(this.eventInfoData));
      localStorage.setItem('activeName', activeName);
      localStorage.setItem('setOrgId', JSON.stringify(this.setOrgId));
      this.$router.push('/login');
    },
    collapseMenu() {
      if (!this.disabled) {
        this.isCollapse = !this.isCollapse;
        this.$store.commit('changeIsCollapse', this.isCollapse);
      } else {
        this.toast('当前分辨率过小。影响使用', 'warning');
      }
    },
    // 个人中心
    personalCenter() {
      this.$router.push({path: '/personalCenter'});
    },
    handleOrgChange() {
    }
  }
};
</script>
<style scoped lang="scss">
.wrap {
  //display: flex;
  //flex-direction: row;
  height: 100%;
  width: 100%;
  font-family: 'PingFang SC', 'Hiragino Sans GB', 'Helvetica Neue', 'Microsoft YaHei', Arial, Helvetica, sans-serif;
  overflow: hidden;

  .header {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    background-color: #1690FF;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);
    padding: 0 24px;

    .header-left {
      font-size: 22px;
      color: #193483;
      height: 30px;
      width: 30px;

      i {
        display: inline-block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        color: #ffffff;
      }

      span {
        margin-left: 16px;
      }
    }
    .tenant-name {
    color: #ffffff;
    white-space: nowrap;
  }
    .top-router {
      //width: calc(100% - 600px);
      flex: 1;
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      justify-content: start;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
      ::v-deep .el-tabs__header {
        // margin-left: 10px;
        background-color: #1690FF;
      }

      ::v-deep .el-tabs__nav {
        margin-left: 10px;
        background-color: #1690FF;

        .el-tabs--top .el-tabs__item.is-top:last-child {
          padding-right: 20px;
        }
      }

      ::v-deep .el-tabs__nav-wrap::after {
        background-color: unset;
        color: unset;
      }
      ::v-deep .el-tabs__item.is-top {
            color: #ffffff;
      }
      ::v-deep .el-tabs__item.is-top.is-active {
        //border-bottom: 3px solid #fff;
        position: relative;
        z-index: 1;
        box-sizing: border-box;
      }

      ::v-deep .el-tabs__nav-next, ::v-deep .el-tabs__nav-prev {
        color: #FFFFFF;
        line-height: 40px;
      }
      .tabLabel {
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        color: #ffffff;
        position: relative;
      }
      .tabLine {
        position: absolute;
        bottom: 0px;
        left: 4px;
        width: calc(100% - 6px);
        height: 3px;
        background: #ffffff;
      }
    }

    .header-right {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #666666;

      .item {
        font-size: 14px;
        margin-left: 24px;
        cursor: pointer;
        color: #ffffff;
      }

      .usr_info {
        margin-left: 24px;
      }
    }

    .header-right span:hover {
      color: #1890ff;
    }
  }

  .menu {
    background-color: #fdfdfd;
    width: auto;

    &.menu_64 {
      width: 64px;
    }

    &.menu_auto {
      width: auto;
    }

    .menu-content {
      overflow-y: auto;
      //height: calc(100% - 170px);
      height: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .menu-footer {
      display: flex;
      height: 114px;
      width: 200px;
      justify-content: center;
      align-items: center;

      .version {
        position: fixed;
        bottom: 4px;
        left: 4px;
        color: #ffffff;
      }

      img {
        height: 114px;
        width: 114px;
      }
    }
  }

  .main {
    flex-direction: column;
    flex: 1;
    background-color: #eaeff8;
    overflow: auto;

    .right-click-menu {
      position: fixed;
      width: 120px;
      background-color: #fff;
      z-index: 2050;
      //border-radius: 5px;
      -webkit-box-shadow: 1px 2px 10px #ccc;
      box-shadow: 1px 2px 10px #ccc;

      .item {
        cursor: pointer;
        font-size: 14px;
        padding: 8px 20px 8px 15px;
        color: #606266;

        &:hover {
          background-color: #1d80fe;
          color: #fff;
        }
      }
    }

    .content {
      margin: 0 24px 20px;
    }
  }
}

.dialog {
  div {
    margin-bottom: 16px;
  }

  img {
    height: 20px;
    width: 20px;
    margin-right: 8px;
    vertical-align: sub;
  }

  .backImg {
    width: 100%;
    height: 44px;
    margin: 0;
    vertical-align: bottom;
  }
}

.un-auth-mask {
  position: absolute;
  top: 100px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  opacity: 0;
}
.el-tabs--border-card > .el-tab-panel{
  padding: 0!important;
}
</style>
<style lang="scss">
.el-tabs--border-card > .el-tabs__content {
  padding: 0;
}
</style>
